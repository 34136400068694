ion-content {
  --background: var(--ion-color-light-shade);
}

ion-toolbar {
  --background: var(--ion-color-dark);
  --color: var(--ion-color-dark-contrast);
}

.dark-backdrop {
  background: rgba(0,0,0,.80);
}

.progress-overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: rgba(0,0,0,0.8);
  border-radius: 3px;
}

.mat-btn-lg {
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
}


